<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_GROUP_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'OrganizationGroupEdit',
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      pageName: 'Organization group',
      rolePerm: 'organization_organizationgroup_retrieve',
      editRolePerm: 'organization_organizationgroup_update'
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_GROUP_TYPES.GENERIC.organization.organizationgroup.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...ORG_GROUP_TYPES.GENERIC.organization.organizationgroup.DETAIL.ACTIONS
    })
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      description: {}
    }
  }
}
</script>
