<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_GROUP_TYPES } from './store'
import { ROUTES as ORG_GROUP_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import OrganizationGroupTable from './OrganizationGroupTable'
import OrganizationGroupCommonFilters from './OrganizationGroupCommonFilters'
import OrganizationGroupEdit from './OrganizationGroupEdit.vue'

export default {
  name: 'OrganizationGroupList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': OrganizationGroupTable,
    'common-filters': OrganizationGroupCommonFilters,
    'component-detail': OrganizationGroupEdit,
    'component-edit': OrganizationGroupEdit
  },
  data () {
    return {
      ORG_GROUP_ROUTES,
      title: this.$t('Organization Categories'),
      rolePerm: ['organization_organizationgroup_list'],
      actionEnablePermission: ['organization_organizationgroup_enable'],
      actionDisablePermission: ['organization_organizationgroup_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_GROUP_TYPES.GENERIC.organization.organizationgroup.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.ORG_GROUP_ROUTES.ORGANIZATION_GROUP_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organizationgroup_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ORG_GROUP_TYPES.GENERIC.organization.organizationgroup.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_GROUP_TYPES.GENERIC.organization.organizationgroup.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_GROUP_TYPES.GENERIC.organization.organizationgroup.LIST.ACTIONS
    })
  }
}
</script>
